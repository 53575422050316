import React, { Fragment, PureComponent } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import $ from "jquery";
import { connect } from "react-redux";
import { GET_STORAGE, USER, AJAX_PUBLIC_REQUEST, AJAX_REQUEST, DELETE_COOKIE, REMOVE_STORAGE, MEAL_MENU_PUBLIC, BUNDLE_ITEM_COUNT } from "../../Constants/AppConstants";
import { logout } from '../../Store/actions/loginActions';
import CartList from "./CartList";
import Parser from "html-react-parser";
import history from "../../history";
// import PrestigeLogo from './../../Assets/images/prestige_logo.svg'
import PrestigeLogo from './../../Assets/images/new_prestige_logo.svg'
import SearchIcon from './../../Assets/images/public_images/header_search_icon.svg'
import MyAccount from './../../Assets/images/customer_images/my_account_icon.svg'
import CartIcon from './../../Assets/images/customer_images/header_cart_icon.svg'
import MenuDropdown from './../../Assets/images/customer_images/menu_dropdown.svg'
import MenuDropdownBlack from './../../Assets/images/customer_images/menu_dropdown_black.svg'
import BestSeller from './../../Assets/images/customer_images/best_sellers_img.svg'
import Basecollect from './../../Assets/images/customer_images/base_collection_img.svg'
import BoostCollect from './../../Assets/images/customer_images/boost_collection_img.svg'
import Bundles from './../../Assets/images/customer_images/bundles_icon.svg'
import BalanceCollect from './../../Assets/images/customer_images/balance_col_img.svg'
import Protein from './../../Assets/images/customer_images/protein_img.svg'
import Workout from './../../Assets/images/customer_images/pro_workout_img.svg'
import WeightLoss from './../../Assets/images/customer_images/weight_loss_img.svg'
import BundleMuscle from './../../Assets/images/customer_images/build_muscle_img.svg'
import EveryDayHealth from './../../Assets/images/customer_images/everyday_helth_img.svg'
import QuestionImg from './../../Assets/images/customer_images/question_img.svg'
import Chart3d from './../../Assets/images/customer_images/chart_3d_img.svg'
import ContactImg from './../../Assets/images/customer_images/contactus_img.svg'
import FaqImg from './../../Assets/images/customer_images/faq_img.svg'
import AffLab from './../../Assets/images/customer_images/aff_lab.svg'
import { Row, Col } from "react-bootstrap";


class Header extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(GET_STORAGE(USER)),
            page: "",
            menus: [],
            showusermenu: false
        };

        setInterval(
            function () {
                if (this.props.auth) {
                    if (this.props.auth.user) {
                        if (this.props.auth.user.remember) {
                            AJAX_REQUEST("POST", "user/updateAccessToken", {}).then(results => {
                                if (parseInt(results.response.code) === 1000) {
                                    // console.log(results.response.code);
                                }
                            });
                        }
                    }
                }
            }.bind(this),
            540000
        );
    }

    logout = (e) => {
        e.preventDefault();
        AJAX_REQUEST("POST", "user/logout", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                DELETE_COOKIE('__kla_id');
                REMOVE_STORAGE('klaviyoStartedCheckout');
            }
        });
        this.props.logout();
    }

    onCliclActiveMob = e => {
        const elements = document.querySelectorAll(".mob_site_content ul li");
        [].forEach.call(elements, function (el) {
            el.classList.remove("active");
        });
        e.currentTarget.classList.add("active");
        document.getElementById("scrollTop").scrollIntoView();
    };

    onClickActive = e => {
        const elements = document.querySelectorAll(".menu-main-menu-container ul li");
        [].forEach.call(elements, function (el) {
            el.classList.remove("active");
        });
        e.currentTarget.classList.add("active");
        document.getElementById("scrollTop").scrollIntoView();
    };

    showMenu = () => {
        this.setState({
            showusermenu: this.state.showusermenu ? false : true
        });
    };

    showMobSideMenu = () => {
        $("body").toggleClass("current", 1000);
    };

    componentDidMount() {
        AJAX_PUBLIC_REQUEST("POST", "menu/getMenuInfo", { type: "primary" }).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    menus: results.response.data
                });
            }
        });
        AJAX_PUBLIC_REQUEST("POST", "page/getContactInfo", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    page: results.response.data
                });
            }
        });
    }

    render() {
        const user = this.state.user;
        const is_meal_available = MEAL_MENU_PUBLIC && this.props.meals.length > 0 && this.props.meals[0] ? true : false;
        let bundle = GET_STORAGE("BundleCart") ? JSON.parse(GET_STORAGE("BundleCart")) : [];
        let cart_count = 0;
        if (this.props.cart[0] || (bundle != null)) {
            if (this.props.cart[0] && this.props.cart[0].length) {
                cart_count = Number(cart_count) + Number(this.props.cart[0].length);
            } else if (BUNDLE_ITEM_COUNT() > 1) {
                cart_count = 1;
            } else {
                cart_count = 0;
            }
        }
        if (is_meal_available) {
            if (this.props.meals[0].hasOwnProperty('items') && this.props.meals[0].items.length > 0) {
                cart_count = Number(cart_count) + Number(this.props.meals[0].items.length);
            }
        }

        const email = this.state.page.hasOwnProperty('email') ? Parser(this.state.page.email) : '';
        const phone = this.state.page.hasOwnProperty('phone') ? Parser(this.state.page.phone) : '';

        let meal_menu_access = GET_STORAGE('meal_menu_access');
        if (!meal_menu_access) {
            meal_menu_access = 'false';
        }

        // let meal_menu_active = false;
        // if(this.props){
        //     if(this.props.auth){
        //         if(this.props.auth.user){
        //             if(this.props.auth.user.meal_menu_activated){
        //                 meal_menu_active = true;
        //             }
        //         }
        //     }
        // }

        let settings = null;
        if (GET_STORAGE("settings")) {
            settings = JSON.parse(GET_STORAGE("settings"));
        }

        let meal_menu_active = true;
        let enable_new_signup = true;
        let distributor_name = settings = JSON.parse(GET_STORAGE("distributorName"));
        console.log(distributor_name);
        // if(settings && settings.enable_new_signup == "yes"){
        //     enable_new_signup = true;
        // }
        // if(settings && settings.meal_menu_public == "yes"){
        //     meal_menu_active = true;
        // } else {
        //     if (this.props) {
        //         if (this.props.auth) {
        //             if (this.props.auth.user) {
        //                 if (this.props.auth.user.meal_menu_activated) {
        //                     meal_menu_active = true;
        //                 }
        //             }
        //         }
        //     }
        // }

        return (
            <React.Fragment>
                <header className="montserrat site-header header_blk">
                    <div className="mob_menu_wrapper d-sm-block d-md-none">
                        <div className="site_menu_wrapper_inner headere_mobile">
                            <div className="mob_site_menu" onClick={this.showMobSideMenu}>
                                <div className="main_menu_blk">
                                    <ul className="mob_site_content">
                                        <li className="menu_close d-flex justify-content-end">
                                            <span className="d-block font_20 text_black fw_ar_bold text-right" onClick={this.showMobSideMenu}>&times;</span>
                                        </li>
                                        {/* Shop HTML */}
                                        <li class="dropdown position-static header_sub_menu">
                                            <a id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>SHOP</span><img src={MenuDropdown} alt="dropDown" /></a>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <div className="container">
                                                    <Row className="shop_menu align-items-center justify-content-center">
                                                        <Col md={12} className="d-flex align-items-center justify-content-center">
                                                            <a href="/products" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Shop All</a>
                                                        </Col>
                                                        <Col md={12} className="d-flex align-items-center justify-content-center">
                                                            <a href="/best-sellers" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Best Sellers</a>
                                                        </Col>
                                                        <Col md={12} className="d-flex align-items-center justify-content-center">
                                                            <a href="/bundle-product" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Bundles</a>
                                                        </Col>
                                                        <Col md={12} className="d-flex align-items-center justify-content-center">
                                                            <a href="/create-custom-bundle" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Build Your Own Bundles</a>
                                                        </Col>

                                                        {/* <Col md={10}>
                                                            <Row className="shop_menu">
                                                                <Col className="w-20">
                                                                    <a href="/top-sellers" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/efa_img.png")} alt="best seller" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Best Sellers</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="/bundle-product" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Bundles} alt="bundles" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Bundles</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/sleep-multiplier-img.png")} alt="Base Collection" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Base Collection</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/test-storm-img.png")} alt="Boost Collection" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Boost Collection</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/multi-power-vitality-for-her-img.png")} alt="Balance Collection" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Balance Collection</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Protein} alt="Protein" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Protein</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/pre-1-img.png")} alt="Pre/Intra Workout" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Pre/Intra Workout</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/am_formula_img.png")} alt="Weight Loss" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Weight Loss</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={BundleMuscle} alt="Build Muscle" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Build Muscle</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/refer_images/power-greens-img.png")} alt="Everyday Health" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Everyday Health</span>
                                                                    </a>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={2} className="d-flex align-items-center justify-content-center">
                                                            <a href="/products" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Shop All</a>
                                                        </Col> */}
                                                    </Row>
                                                </div>
                                            </div>
                                        </li>
                                        {/* Shop HTML Ends */}

                                        {/* Get Started HTML */}
                                        {/* <li class="dropdown position-static header_sub_menu">
                                            <a id="getSytarted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>GET STARTED</span></a>
                                            <div class="dropdown-menu" aria-labelledby="getSytarted">
                                                <div className="container">
                                                    <Row className="shop_menu align-items-center">
                                                        <Col md={12}>
                                                            <Row className="shop_menu justify-content-center">
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={QuestionImg} alt="Take the Quiz" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Take the Quiz</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="/bundle-product" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Bundles} alt="bundles" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Bundles</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={Chart3d} alt="chart 3d" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Chat with Prestige Labs Advisor</span>
                                                                    </a>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </li> */}
                                        {/* Get Started HTML Ends */}
                                        {/* <li><a className="menu_item" href='#'>TESTIMONIALS</a></li> */}
                                        <li><a className="menu_item" href='/page/about-us'>ABOUT US</a></li>
                                        {/* <li><a className="menu_item"  href='https://shop.prestigelabs.com/blogs/events' target="_blank">BLOG</a></li> */}
                                        {/* Support HTML */}
                                        <li class="dropdown position-static header_sub_menu">
                                            <a id="getSytarted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>Support</span><img src={MenuDropdown} alt="dropDown" /></a>
                                            <div class="dropdown-menu" aria-labelledby="getSytarted">
                                                <div className="container">
                                                    <Row className="shop_menu align-items-center">
                                                        <Col md={12}>
                                                            <Row className="shop_menu justify-content-center">
                                                                <Col md={12} className="d-flex align-items-center justify-content-center">
                                                                    <a href="/contact" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Contact Us</a>
                                                                </Col>
                                                                <Col md={12} className="d-flex align-items-center justify-content-center">
                                                                    <a href="/page/faq-info" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">FAQs</a>
                                                                </Col>
                                                                <Col md={12} className="d-flex align-items-center justify-content-center">
                                                                    <a href="https://affiliate.prestigelabs.com/login" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Become a Prestige Labs Affiliate</a>
                                                                </Col>
                                                                {/* <Col className="w-20">
                                                                    <a href="/contact" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/customer_images/Contact_Us.png")} alt="contact img" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Contact Us</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="/page/faq-info" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require("../../Assets/images/customer_images/FAQ.png")} alt="faq" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">FAQ’s</span>
                                                                    </a>
                                                                </Col>
                                                                <Col className="w-20">
                                                                    <a href="https://affiliate.prestigelabs.com/login" target="_blank" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                        <img src={require('../../Assets/images/customer_images/Become_an_Affiliate.png')} alt="aff lab img" />
                                                                        <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Become a Prestige Labs Affiliate</span>
                                                                    </a>
                                                                </Col> */}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </li>
                                        {/* Support HTML Ends */}
                                    </ul>
                                </div>

                            </div>

                            {/* <div className="mob_main_user" onClick={this.showMenu} /> */}

                            {
                                this.props.auth.isAuthenticated ?
                                    <Fragment>
                                        <div className="header_right_blk ">
                                            <NavLink to="/" exact className="mobile_logo_blk" onClick={(e) => {
                                                e.preventDefault(); 
                                                window.location.href = "/";
                                            }}>
                                                <img src={PrestigeLogo} alt="Prestige Labs" />
                                            </NavLink>
                                            <ul className="right_blk_menu d-flex align-items-center">
                                                {/* <li>
                                                    <a href="#">
                                                        <img src={SearchIcon} alt="searchIcon" />
                                                    </a>
                                                </li> */}
                                                <li class="dropdown">
                                                    <span activeClassName="active" className="menu_item d-flex cursor-pointer" exact id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={MyAccount} alt="MyAccount" /></span>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <NavLink class="font_16 text_bloack fw_el_reg" to="/my-account">My Account</NavLink>
                                                        <a class="font_16 text_bloack fw_el_reg" onClick={this.logout} style={{ marginRight: '0' }} href="#"> Logout</a>
                                                    </div>
                                                </li>
                                                {distributor_name ? (
                                                    <li className="font_14 text_white fw_el_reg justify-content-end">Shopping with {distributor_name}</li>
                                                ) : null}
                                                <li>
                                                    <a href="/cart" className="position-relative" id="cartToggle">
                                                        <span id="cartCount">
                                                            <span className="count position-absolute cart_count">{cart_count}</span>
                                                        </span>
                                                        <img src={CartIcon} alt="CartIcon" />
                                                    </a>
                                                    <div className="widget_shopping_cart_content d-none">
                                                        <CartList />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </Fragment>
                                    :
                                    <Fragment>
                                        <div className="header_right_blk before_login">
                                            <NavLink to="/" exact className="mobile_logo_blk" onClick={(e) => {
                                                e.preventDefault(); 
                                                window.location.href = "/";
                                            }}>
                                                <img src={PrestigeLogo} alt="Prestige Labs" />
                                            </NavLink>
                                            <ul className="right_blk_menu d-flex align-items-center">
                                                <li className="pull-right" key={Math.random()}>
                                                    <NavLink activeClassName="active" style={{ marginRight: '0' }} className="menu_item buy_now fw_ar_reg font_16 text_white d-flex" to="/login" exact > Login</NavLink>
                                                </li>
                                                {distributor_name ? (
                                                    <li className="font_14 text_white fw_el_reg">Shopping with {distributor_name}</li>
                                                ) : null}
                                                <li>
                                                    <NavLink to="/cart">
                                                        <div className="position-relative">
                                                            <img src={CartIcon} alt="CartIcon" />
                                                            <span className="count position-absolute cart_count">{cart_count}</span>
                                                        </div>
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </Fragment>
                            }
                            <form action="" className="header_search_blk position-relative tab_hide">
                                <input type="search" name="" className="form-control" id="" placeholder="Search" />
                                <img src={SearchIcon} alt="search_icon" className="position-absolute" />
                            </form>
                            <NavLink to="/cart" activeClassName="active">
                                <div className="mob_main_cart" />
                            </NavLink>
                        </div>

                        <div className="clearfix" />
                        {this.state.showusermenu ? (
                            <div className="mob_main_user_wrapper">
                                <ul>
                                    <li>
                                        <NavLink activeClassName="active" to="/cart">
                                            Cart
                                        </NavLink>
                                    </li>
                                    {
                                        this.props.auth.isAuthenticated ?
                                            <Fragment>
                                                <li>
                                                    <NavLink activeClassName="active" to="/my-account">
                                                        {enable_new_signup ? 'My Orders' : 'My Account'}
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <a onClick={this.logout} href="#">
                                                        Logout
                                                    </a>
                                                </li>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <li>
                                                    <NavLink activeClassName="active" to="/login">
                                                        Login
                                                    </NavLink>
                                                </li>
                                            </Fragment>
                                    }
                                </ul>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="hide_small_screen">
                        <div className="container-fluid">
                            <div className="logo-wrapper">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="site-logo">
                                        {/* <div exact>
                                            <img src={PrestigeLogo} className="attachment-full size-full" alt="" title="" />
                                        </div> */}
                                        <NavLink to="/" exact className="">
                                            <img src={PrestigeLogo} alt="Prestige Labs" />
                                        </NavLink>
                                    </div>
                                    <div className="main_menu_blk">
                                        <ul id="menu-main-menu" className="menu d-flex align-items-center">
                                            {/* Shop HTML */}
                                            <li class="dropdown position-static header_sub_menu">
                                                <a id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>SHOP</span><img src={MenuDropdown} alt="dropDown" /></a>
                                                <div class="dropdown-menu shop_menu_blk " aria-labelledby="dropdownMenuButton">
                                                    <div className="container">
                                                        <Row className="shop_menu shop_menu align-items-center justify-content-center">
                                                            <Col md={12} className="d-flex align-items-center justify-content-center">
                                                                <a href="/products" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Shop All</a>
                                                            </Col>
                                                            <Col md={12} className="d-flex align-items-center justify-content-center">
                                                                <a href="/best-sellers" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Best Sellers</a>
                                                            </Col>
                                                            <Col md={12} className="d-flex align-items-center justify-content-center">
                                                                <a href="/bundle-product" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Bundles</a>
                                                            </Col>
                                                            <Col md={12} className="d-flex align-items-center justify-content-center">
                                                                <a href="/create-custom-bundle" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Build Your Own Bundles</a>
                                                            </Col>
                                                            {/* <Col md={10}>
                                                                <Row className="shop_menu">
                                                                    <Col className="w-20">
                                                                        <a href="/top-sellers" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={require("../../Assets/images/refer_images/efa_img.png")} alt="best seller" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Best Sellers</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="/bundle-product" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={Bundles} alt="bundles" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Bundles</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={require("../../Assets/images/refer_images/sleep-multiplier-img.png")} alt="Base Collection" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Base Collection</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={require("../../Assets/images/refer_images/test-storm-img.png")} alt="Boost Collection" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Boost Collection</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={require("../../Assets/images/refer_images/multi-power-vitality-for-her-img.png")} alt="Balance Collection" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Balance Collection</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={Protein} alt="Protein" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Protein</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={require("../../Assets/images/refer_images/pre-1-img.png")} alt="Pre/Intra Workout" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Pre/Intra Workout</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={require("../../Assets/images/refer_images/am_formula_img.png")} alt="Weight Loss" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Weight Loss</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={BundleMuscle} alt="Build Muscle" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Build Muscle</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={require("../../Assets/images/refer_images/power-greens-img.png")} alt="Everyday Health" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Everyday Health</span>
                                                                        </a>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={2} className="d-flex align-items-center justify-content-center">
                                                                <a href="/products" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Shop All</a>
                                                            </Col> */}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </li>
                                            {/* Shop HTML Ends */}

                                            {/* Get Started HTML */}
                                            {/* <li class="dropdown position-static header_sub_menu">
                                                <a id="getSytarted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>GET STARTED</span></a>
                                                <div class="dropdown-menu" aria-labelledby="getSytarted">
                                                    <div className="container">
                                                        <Row className="shop_menu align-items-center">
                                                            <Col md={12}>
                                                                <Row className="shop_menu justify-content-center">
                                                                    <Col className="w-20">
                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={QuestionImg} alt="Take the Quiz" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Take the Quiz</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="/bundle-product" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={Bundles} alt="bundles" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Bundles</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={Chart3d} alt="chart 3d" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Chat with Prestige Labs Advisor</span>
                                                                        </a>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </li> */}
                                            {/* Get Started HTML Ends */}
                                            {/* <li><a className="menu_item" href='#'>TESTIMONIALS</a></li> */}
                                            <li><a className="menu_item" href='/page/about-us'>ABOUT US</a></li>
                                            {/* <li><a className="menu_item"  href='https://shop.prestigelabs.com/blogs/events' target="_blank">BLOG</a></li> */}
                                            {/* Support HTML */}
                                            <li class="dropdown position-static header_sub_menu">
                                                <a id="getSytarted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>Support</span><img src={MenuDropdown} alt="dropDown" /></a>
                                                <div class="dropdown-menu" aria-labelledby="getSytarted">
                                                    <div className="container">
                                                        <Row className="shop_menu align-items-center">
                                                            <Col md={12}>
                                                                <Row className="shop_menu justify-content-center">
                                                                    <Col md={12} className="d-flex align-items-center justify-content-center">
                                                                        <a href="/contact" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Contact Us</a>
                                                                    </Col>
                                                                    <Col md={12} className="d-flex align-items-center justify-content-center">
                                                                        <a href="/page/faq-info" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">FAQs</a>
                                                                    </Col>
                                                                    <Col md={12} className="d-flex align-items-center justify-content-center">
                                                                        <a href="https://affiliate.prestigelabs.com/login" className="text_white fw_el_bold d-flex shop_all_link let_spa_3">Become a Prestige Labs Affiliate</a>
                                                                    </Col>
                                                                    {/* <Col className="w-20">
                                                                        <a href="/contact" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={require("../../Assets/images/customer_images/Contact_Us.png")} alt="contact img" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Contact Us</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="/page/faq-info" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={require("../../Assets/images/customer_images/FAQ.png")} alt="faq" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">FAQ’s</span>
                                                                        </a>
                                                                    </Col>
                                                                    <Col className="w-20">
                                                                        <a href="https://affiliate.prestigelabs.com/login" target="_blank" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                                            <img src={require('../../Assets/images/customer_images/Become_an_Affiliate.png')} alt="aff lab img" />
                                                                            <span className="font_16 p-0 text-center d-block text_white mt_17 fw_el_bold">Become a Prestige Labs Affiliate</span>
                                                                        </a>
                                                                    </Col> */}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </li>
                                            {/* Support HTML Ends */}
                                            {/* {this.state.menus.length <= 0
                                                ? null
                                                : this.state.menus.map(function (menu, key) {
                                                    if (menu.type === "external") {
                                                        if (menu.manual_position != 'last') {
                                                            return (
                                                                <li key={"dm" + key}>
                                                                    <a
                                                                        target={menu.open_new_tab == "yes" ? "_blank" : "_self"} className={`menu_item${history.location.pathname ===
                                                                            menu.url
                                                                            ? " active"
                                                                            : ""
                                                                            }`}
                                                                        href={menu.url}
                                                                    >
                                                                        {menu.label}
                                                                    </a>
                                                                </li>
                                                            );
                                                        }
                                                    } else {
                                                        if (menu.url == "/meals") {
                                                            if (meal_menu_active) {
                                                                return (
                                                                    <li key={"dm" + key}>
                                                                        <NavLink
                                                                            activeClassName="active"
                                                                            className="menu_item"
                                                                            to={menu.url}
                                                                            exact
                                                                        >
                                                                            {menu.label}
                                                                        </NavLink>
                                                                    </li>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        } else {
                                                            return (
                                                                <li key={"dm" + key}>
                                                                    <NavLink
                                                                        activeClassName="active"
                                                                        className="menu_item"
                                                                        to={menu.url}
                                                                        exact
                                                                    >
                                                                        {menu.label}
                                                                    </NavLink>
                                                                </li>
                                                            );
                                                        }
                                                    }
                                                })}

                                            {this.state.menus.length <= 0
                                                ? null
                                                : this.state.menus.map(function (menu, key) {
                                                    if (menu.type === "external") {
                                                        if (menu.manual_position == 'last') {
                                                            return (<li key={"dm" + key}>
                                                                <a
                                                                    target={menu.open_new_tab == "yes" ? "_blank" : "_self"} className={`menu_item${history.location.pathname === menu.url
                                                                        ? " active"
                                                                        : ""
                                                                        }`}
                                                                    href={menu.url}
                                                                >
                                                                    <span>{menu.label}</span>
                                                                </a>
                                                            </li>)
                                                        }
                                                    }
                                                })}

                                            {
                                                this.props.auth.isAuthenticated ?
                                                    <Fragment>
                                                        {
                                                            this.props.auth.user.activate_meal ?
                                                                <li className="pull-right" key={Math.random()}>
                                                                    <NavLink className="menu_item" to="/activate-meal" exact > Activate Meal</NavLink>
                                                                </li>
                                                                : ''
                                                        }
                                                    </Fragment>
                                                    : ''
                                            } */}
                                        </ul>
                                    </div>

                                    <div className="header_right_blk">
                                        <ul className="right_blk_menu d-flex align-items-center">
                                            {/* <li>
                                                <form action="" className="header_search_blk position-relative">
                                                    <input type="search" name="" className="form-control" id="" placeholder="Search" />
                                                    <img src={SearchIcon} alt="search_icon" className="position-absolute" />
                                                </form>
                                            </li> */}
                                            {/* <li>
                                                <a href="#">
                                                    <img src={SearchIcon} alt="searchIcon" />
                                                </a>
                                            </li> */}
                                            {
                                                this.props.auth.isAuthenticated ?
                                                    <Fragment>
                                                        {distributor_name ? (
                                                            <li className="shopping_with font_16 text_white fw_el_reg">Shopping with {distributor_name}</li>
                                                        ) : null}
                                                        <li class="dropdown">
                                                            <span activeClassName="active" className="menu_item d-flex cursor-pointer" exact id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={MyAccount} alt="MyAccount" /></span>
                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <NavLink class="font_16 text_black fw_el_reg" to="/my-account">My Account</NavLink>
                                                                <a class="font_16 text_black  fw_el_reg" onClick={this.logout} style={{ marginRight: '0' }} href="#"> Logout</a>
                                                            </div>
                                                        </li>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        {distributor_name ? (
                                                            <li className="shopping_with font_16 text_white fw_el_reg">Shopping with {distributor_name}</li>
                                                        ) : null}
                                                        <li className="pull-right" key={Math.random()}>
                                                            <NavLink activeClassName="active" style={{ marginRight: '0' }} className="menu_item buy_now fw_ar_reg font_16 text_white d-flex" to="/login" exact > Login</NavLink>
                                                        </li>
                                                    </Fragment>
                                            }
                                            <li>
                                                <NavLink to="/cart" className="position-relative">
                                                    <span className="count position-absolute cart_count">{cart_count}</span>
                                                    <img src={CartIcon} alt="CartIcon" />
                                                </NavLink>
                                            </li>
                                            {/* <li>
                                                <a href="/cart" className="position-relative" id="cartToggle">
                                                    <span id="cartCount">
                                                        <span className="count position-absolute cart_count">{cart_count}</span>
                                                    </span>
                                                    <img src={CartIcon} alt="CartIcon" />
                                                </a>
                                                <div className="widget_shopping_cart_content d-none">
                                                    <CartList />
                                                </div>
                                            </li> */}

                                        </ul>
                                    </div>
                                    {/* <div className="col-md-8">
                                        <div className="top-header">
                                            <div className="header-top header_contact">
                                                <div className="top-widgets-right">
                                                    <div className="textwidget roboto topemailphonecolor">
                                                        Reach us at <a href={`mailto:${email}`}>{email}</a> or <a href={`tel:${phone}`}>{phone}</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wrapper-top-cart text-right">
                                                <div className="top-cart">
                                                    <span className="icon">&nbsp;</span>
                                                    <a href="javascript:void(0)" id="cartToggle">
                                                        <span className="first">Shopping Cart</span>
                                                        <span id="cartCount">
                                                            <span className="cart-contents">
                                                                <span className="count">{cart_count}</span>
                                                            </span>
                                                        </span>
                                                    </a>
                                                    <div className="widget_shopping_cart_content">
                                                        <CartList />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div> */}
                                </div>
                            </div>
                        </div>

                        {/* <section id="scrollTop">
                            <div className="site_menu">
                                <div className="header-logo-fix">
                                    <NavLink to="/" exact>
                                        <img src={require("../../Assets/images/logo_fix.png")} alt="Prestige Labs" />
                                    </NavLink>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <nav className="primary-nav">
                                                <div className="menu-main-menu-container">
                                                  
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className="header-logo-cart">
                                    <span className="icon">&nbsp;</span>
                                    <div className="widget_shopping_cart_content">
                                        <CartList />
                                    </div>
                                </div>
                            </div>
                        </section> */}
                    </div>
                </header >
            </React.Fragment >
        );
    }
}

Header.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        cart: state.cart,
        meals: state.meals
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        { logout }
    )(Header)
);
