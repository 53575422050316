import React, { Fragment, PureComponent } from 'react';
import Parser from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import { AJAX_PUBLIC_REQUEST, SET_STORAGE, GET_STORAGE, REMOVE_STORAGE, CHECK_STORAGE, CURRENCY_FORMAT, BUNDLE_SUB_TOTAL, BUNDLE_OFFER_NEW, ITEM_COUNT } from '../../Constants/AppConstants';
import SingleCustomProductListGrid from './SingleCustomProductListGrid';
import SingleProductModal from './SingleProductModal';
import Pagination from '../Common/Pagination';
import SingleProductNotAvailableModal from './SingleProductNotAvailableModal';
import SingleProductNotAvailable from './SingleProductNotAvailable'
import SearchIcon from './../../Assets/images/customer_images/header_search.svg'
import HomeIcon from './../../Assets/images/refer_images/home_icon.svg'
import UploadIcon from './../../Assets/images/refer_images/upload_product_icon.svg'
import { Row, Col } from "react-bootstrap";
import AddedCustomProductsList from "./AddedCustomProductsList";
import DeleteIcon from '../../Assets/images/refer_images/bundle-delete.svg';
import ReactImageFallback from "react-image-fallback";
import classnames from 'classnames';
import $ from 'jquery';
import history from "../../history";

class CreateCustomBundle extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            products: [],
            productDetails: {},
            filter_by: 'popular',
            listView: false,
            ProductLink: '',
            productNotAvailable: ["Bulletproof Vitality For Her", "Women's Ultimate", "Women's Immune Booster"],
            // productNotAvailable:[],
            // Pagination Config
            item_count: 0,
            total_records: 0,
            total_page: 0,
            per_page: 0,
            pagenum: 1,
            products_filtered: [],
            products_filters: [],
            AddedProducts: [],
            searchName: '',
            cartLink: '/cart',
            discount_data: (GET_STORAGE("Discount_data")) ? JSON.parse(GET_STORAGE("Discount_data")) : '',
            subscription_detail : '',
            NotallowedtoSubscription: false
        }
        document.title = "Create Custom Bundle - Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        document.body.classList.remove('home_page');
        this.getProducts(this.state.filter_by, this.state.pagenum);
        this.getCart();
    }

    pagenationHandle = (pageNumber) => {
        this.setState({ loading: true });
        this.getProducts(this.state.filter_by, pageNumber);
    }

    gridListView = (e) => {
        this.setState({ listView: !this.state.listView });
    }

    alertModel = (e) => {
        if(ITEM_COUNT() != 0){
            $(`#${'alertModel'}`).modal({
                backdrop: 'static',
                keyboard: false,
            });
        } else{
            history.push("/cart");
        }
    }

    handleCart = (e) => {
        history.push("/cart");
    }

    filterBy = (e) => {
        let filter_by = e.target.value;
        this.setState({
            loading: true,
            [e.target.name]: filter_by
        })
        this.getProducts(filter_by, 1);
    }

    getProducts = (filterBy, pageNumber) => {
        const data = {
            filter_by: filterBy,
            // pagenum: parseInt(pageNumber)
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getCustomBundleList", data).then(results => {
            if (results.response.code === 1000) {
                this.setState({
                    product_filter: results.response.data,
                    products: results.response.data,
                    products_filtered: results.response.data,
                    loading: false,
                    // Pagination Config
                    item_count: parseInt(results.response.data.length),
                    total_records: parseInt(results.response.total_records),
                    product_discount: results.response.product_discount,
                    subscription_detail: results.response.subscription_detail,
                    subscription : 'no'
                    // total_page: parseInt(results.response.total_page),
                    // per_page: parseInt(results.response.per_page),
                    // pagenum: parseInt(results.response.pagenum),
                });

                SET_STORAGE("productDiscount", JSON.stringify(results.response.product_discount));
                SET_STORAGE("CustomSubscriptionDetail", JSON.stringify(results.response.subscription_detail));
                SET_STORAGE("is_subscription", 'no');
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                    // Pagination Config
                    item_count: 0,
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                })
            }
        });
    }

    quickView = (productId) => {
        this.setState({ productDetails: {} })
        const data = {
            product_id: productId,
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getDetails", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    productDetails: results.response.data,
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                })
            }
        });
    }

    notAvailablePopup = (link) => {
        this.setState({
            ProductLink: link,
        });
    }

    //filter
    filterProduct = (e) => {
        this.setState({
            searchName: e.target.value,
            products_filtered: this.state.product_filter.filter(function (product) {
                if (e.target.value === '') {
                    return product;
                } else {
                    let string = product.title.toUpperCase();
                    let substring = e.target.value.toUpperCase();
                    if (string.includes(substring)) {
                        return product;
                    }
                }
            }.bind(this))
        });
    }

    getCart = () => {
        BUNDLE_OFFER_NEW();
        let discount_data_new = (GET_STORAGE("Discount_data")) ? JSON.parse(GET_STORAGE("Discount_data")) : '';
        this.setState({ discount_data: discount_data_new });
        if (GET_STORAGE("Bundle")) {
            const products = JSON.parse(GET_STORAGE("Bundle"));
            const discountApplied = this.state.discount_data === discount_data_new;
            if (discount_data_new.length > 0 && !discountApplied) {
                const updatedProducts = products.map((product, index) => {
                    const Inddiscount = discount_data_new[index]; 
                    const newSalePrice = (product.cart_sale_price - Inddiscount);
                    return {
                        ...product,
                        custom_bundle_discount_price: newSalePrice.toFixed(2),
                        custom_bundle_discount: Inddiscount.toFixed(2)
                    };
                });
                this.setState({
                    AddedProducts: updatedProducts,
                    discount_data: discount_data_new
                });
                SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
            } else {
                this.setState({ AddedProducts: products, discount_data: discount_data_new });
            }
            const hasSubscriptionStatusZero = products.some(product => (product.subscription_status == "0" || product.subscription_status == null));
            this.setState({ NotallowedtoSubscription: hasSubscriptionStatusZero ? true : false });
        } else {
            this.setState({ AddedProducts: [] });
            SET_STORAGE("Bundle", []);
            SET_STORAGE("BundleCart", []);
        }
    };
    
    updateAddedProducts = (newProduct) => {
        BUNDLE_OFFER_NEW();
        let discount_data_new = JSON.parse(GET_STORAGE("Discount_data"));
        const products = JSON.parse(newProduct);
        const discountApplied = this.state.discount_data === discount_data_new;
        if (discount_data_new.length > 0 && !discountApplied) {
            const updatedProducts = products.map((product, index) => {
                const Inddiscount = discount_data_new[index]; 
                const newSalePrice = (product.cart_sale_price - Inddiscount);
                return {
                    ...product,
                    custom_bundle_discount_price: newSalePrice.toFixed(2),
                    custom_bundle_discount: Inddiscount.toFixed(2)
                };
            });
            this.setState({
                AddedProducts: updatedProducts,
                discount_data: discount_data_new
            });
            SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
        } else {
            this.setState({
                AddedProducts: JSON.parse(newProduct),
                discount_data: discount_data_new
            });
        }
        if (this.state.subscription == "yes") {
            const formattedTotal = CURRENCY_FORMAT(BUNDLE_SUB_TOTAL());
            const numericalTotal = parseFloat(formattedTotal.replace(/[^0-9.-]+/g,""));
            const discountPrice = (numericalTotal - (numericalTotal * this.state.subscription_detail.subscription_save_percentage / 100)).toFixed(2);
            this.setState({ discountPrice });
            SET_STORAGE("is_subscription", 'yes');
        }
        const hasSubscriptionStatusZero = products.some(product => (product.subscription_status == "0" || product.subscription_status == null));
        this.setState({ NotallowedtoSubscription: hasSubscriptionStatusZero ? true : false });
    }

    deleteItem = (e, row_id) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete the item?")) {
            let cart = JSON.parse(GET_STORAGE("Bundle"));
            if (cart.splice(row_id, 1)) {
                this.setState({
                    success_alert_wrapper_show: true,
                    server_message: "Item removed from cart successfully"
                });
                SET_STORAGE("Bundle", JSON.stringify(cart));
                let product_discount = JSON.parse(GET_STORAGE("productDiscount"));             
                let totalCountInBundle = cart.length;
                let matchingDiscount = product_discount.find(discountItem => parseInt(discountItem.product_qty) === totalCountInBundle);
                let newDiscountData = 0;
                if (matchingDiscount !== undefined) {
                    newDiscountData = matchingDiscount.discount / matchingDiscount.product_qty;
                }
                SET_STORAGE("Discount_data", JSON.stringify(newDiscountData));
                this.setState({ discount_data: newDiscountData });
        
                this.getCart();
               // this.timeOut(5000);
            }
        }
    };

    alertModel = (e) => {
        if(ITEM_COUNT() != 0){
            $(`#${'alertModel'}`).modal({
                backdrop: 'static',
                keyboard: false,
            });
        } else{
            history.push("/cart");
        }
    }

    handleCart = (e) => {
        history.push("/cart");
    }

    isSubscription = (e) => {
        if(this.state.subscription == "yes"){
            SET_STORAGE("is_subscription", 'no');
        } else if(this.state.subscription == "no"){
            SET_STORAGE("is_subscription", 'yes');
        }
        this.setState ({
            subscription: this.state.subscription == "yes" ? "no" : "yes"
        }, () => {
            if (this.state.subscription == "yes") {
                const formattedTotal = CURRENCY_FORMAT(BUNDLE_SUB_TOTAL());
                const numericalTotal = parseFloat(formattedTotal.replace(/[^0-9.-]+/g,""));
                const discountPrice = (numericalTotal - (numericalTotal * this.state.subscription_detail.subscription_save_percentage / 100)).toFixed(2);
                this.setState({ discountPrice });
                SET_STORAGE("is_subscription", 'yes');
            }
        });
    };

    render() {
        const quickView = this.quickView;
        const notAvailablePopup = this.notAvailablePopup;
        const product_discount = this.state.product_discount;
        const addedProducts = this.state.AddedProducts;
        let totalProductQtyAdded = 0;
        addedProducts.forEach(product => {
            totalProductQtyAdded += parseInt(product.quantity);
        });
        const maxProductQty = (product_discount != undefined) ? Math.max(...product_discount.map(discount => parseInt(discount.product_qty))) : 0;
        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>
                            <div className="suplements_blk custom_bundle_blk p-0">
                                <div className="container-fluid p-0">

                                    <div className="row m-0">
                                        <div className="col-md-8 p-0">
                                            <main className="athletes_list_wrapper custom_bunde_left">


                                                <div className="d-flex align-items-center respons_flex_direction mb_28 justify-content-between">
                                                    <div className="pro_head_blk">
                                                        <h2 className="product_itle text_black let_spa_2 font_24 fw_el_semi mb_6 mobile_hide">Build your own bundle</h2>
                                                        <nav aria-label="breadcrumb" className="m-0 mobile_hide">
                                                            <ol className="breadcrumb cus_breadcrumb pro_breadcrumb">
                                                                <li className="breadcrumb-item"><NavLink to="/"><img src={HomeIcon} alt="home_icon" /></NavLink></li>
                                                                <li className="breadcrumb-item active" aria-current="page">  ~ Supplements ~ Build your Own Bundle </li>
                                                            </ol>
                                                        </nav>
                                                    </div>
                                                    <div className="input_bundle_btn d-flex justify-content-end align-items-center">
                                                        <form className="product-search-form nxt_bundle_btn w-50 m-0 product_search_input position-relative form_input_blk" method="get">
                                                            <div className="search-input-group">
                                                                <input onChange={this.filterProduct} value={this.state.searchName}
                                                                    className="form-control" placeholder="Search" name="searchName" id="filter-by" type="text" />
                                                            </div>
                                                            <div className="search_icon position-absolute d-flex align-items-center justify-content-center">
                                                                <img src={SearchIcon} alt="search icon" />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                {/* <h4>Add products to create your custom bundle here</h4> */}

                                                <div className="row sup_pro_row">
                                                    {this.state.products_filtered.length <= 0
                                                        ? <h3>No Product Matched!</h3>
                                                        : this.state.products_filtered.map(
                                                            function (product, key) {
                                                                product.notAvailablePopup = this.state.productNotAvailable.includes(product.title) ? this.notAvailablePopup : "";
                                                                // Added product quickviewer
                                                                product.quickView = this.quickView;
                                                                return this.state.topSellerShow <= key ? (
                                                                    ""
                                                                ) :
                                                                    (this.state.productNotAvailable.includes(product.title)) ?
                                                                        <div key={Math.random()} className={this.state.listView ? 'col-xs-12 col-sm-4 col-md-4 col-lg-3 list_view' : 'col-xs-12 col-sm-4 col-md-4 col-lg-3'}>
                                                                            <SingleProductNotAvailable product={product} />
                                                                        </div> :
                                                                        (product.is_bundle == 0 ?
                                                                            <SingleCustomProductListGrid
                                                                            key={Math.random()}
                                                                             product={product}
                                                                             subscription={this.state.subscription}
                                                                             updateAddedProducts={this.updateAddedProducts} // Pass the callback function
                                                                         /> : ""
                                                                        );
                                                            }.bind(this)
                                                        )}
                                                </div>

                                                {this.state.searchName == '' ?
                                                    <Pagination
                                                        pagenationHandle={this.pagenationHandle}
                                                        total_records={this.state.total_records}
                                                        total_page={this.state.total_page}
                                                        per_page={this.state.per_page}
                                                        pagenum={this.state.pagenum}
                                                    />
                                                    : ""
                                                }
                                            </main>
                                        </div>
                                        <div className="col-md-4 p-0 Bundle_Added">
                                            <div className='custom_bunde_left'>
                                                <h2 className="product_itle text_black let_spa_2 font_24 fw_el_semi mb_6 mobile_show">Build your own bundle</h2>
                                                <nav aria-label="breadcrumb" className="mb_20 mobile_show">
                                                    <ol className="breadcrumb cus_breadcrumb pro_breadcrumb">
                                                        <li className="breadcrumb-item"><NavLink to="/"><img src={HomeIcon} alt="home_icon" /></NavLink></li>
                                                        <li className="breadcrumb-item active" aria-current="page">  ~ Supplements ~ Build your Own Bundle </li>
                                                    </ol>
                                                </nav>
                                                <h2 className="font_24 mt-0 mb_15 fw-bold">Your Product Bundle</h2>
                                                <div className='bundle_progress_bar w-100 mb_22'>
                                                <ul className='d-flex flex-wrap w-100'>
                                                        {product_discount !== undefined &&
                                                            product_discount
                                                                .sort((a, b) => parseInt(a.product_qty) - parseInt(b.product_qty))
                                                                .map((discount, index) => {
                                                                    const addedProducts = this.state.AddedProducts || [];
                                                                    const productQty = parseInt(discount.product_qty);
                                                                    let totalProductQtyAdded = 0;
                                                                    addedProducts.forEach(product => {
                                                                        totalProductQtyAdded += parseInt(product.quantity);
                                                                    });
                                                                    const progressPercentage = totalProductQtyAdded > 0 ? (Math.min(totalProductQtyAdded, productQty) / productQty) * 100 : 0;
                                                                    const isProductAdded = totalProductQtyAdded >= productQty;
                                                                    const productText = discount.product_qty == 1 ? 'Item' : 'Items';
                                                                    const isMaxProductQty = maxProductQty == discount.product_qty;
                                                                   
                                                                    return (
                                                                        <li key={index} className={`${isProductAdded ? 'prod_added' : ''}`}>
                                                                            <div className="discount-progress-bar">
                                                                                <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
                                                                            </div>
                                                                            <span className="round_circle d-flex justify-content-center align-items-center font_12 mb_5 mx-auto">
                                                                                {discount.discount}%
                                                                            </span>
                                                                            <span className="font_10 text_black d-block text-center">
                                                                                {discount.product_qty} {isMaxProductQty && <span className="plus">+</span>} {productText}
                                                                            </span>
                                                                        </li>
                                                                    );
                                                                })}
                                                    </ul>

                                                </div>
                                                <div className='right_bundle_blk'>
                                                    {/* Start added product list*/}
                                                    {
                                                        this.state.AddedProducts?.map(function (product, key) {
                                                            // Added item delete and reApplyCoupon methos
                                                            product.deleteItem = this.deleteItem;
                                                            product.row_id = key;
                                                            SET_STORAGE("qty", JSON.stringify(product.quantity));
                                                            return (
                                                                // <AddedCustomProductsList key={Math.random() + '-' + product.cart_variation_id} product={product} />
                                                                <div className={classnames("mb_22 cart_pro_list d-flex align-items-center justify-content-between", { 'pl_error_input': (product.variation_status == "0" || product.product_status == "0" || this.state.product_status == 0) })} key={product.row_id}>
                                                                    <div className="cart_pro_list_left w-100 p-0 d-flex align-items-center">
                                                                        <div className="cart_pro_img">
                                                                            <ReactImageFallback
                                                                                src={product.cart_image}
                                                                                fallbackImage={require('../../Assets/images/preloader.gif')}
                                                                                initialImage={require('../../Assets/images/preloader.gif')}
                                                                                alt=''
                                                                                className="cart_product_img" />
                                                                        </div>
                                                                        <div className="cart_pro_det pl_13">
                                                                            <div className='d-flex align-items-start justify-content-between head_del_blk mb_0'>
                                                                                <h4 className="font_16 text_black let_spa_2 fw_el_bold m-0 line_height_25">{product.cart_product_name}</h4>
                                                                                <div data-title="Remove" className='d-flex'>
                                                                                    <a onClick={(e) => product.deleteItem(e, product.row_id)} href="#" className="font_12 d-flex red_text fw_ar_reg"><img src={DeleteIcon} /></a>
                                                                                </div>
                                                                            </div>
                                                                            {/* <span className="d-block items_text font_14 fw_ar_reg mb_9">{product.cart_variation_name}</span> */}
                                                                            {product.cart_variation_name !== '1 month' && (
                                                                                <span className="d-block items_text font_14 fw_ar_reg mb_9">
                                                                                    {product.cart_variation_name}
                                                                                </span>
                                                                            )}
                                                                            <span className="d-block text_green font_12 fw_ar_reg mb_9">{BUNDLE_OFFER_NEW()}% OFF</span>
                                                                            <div className='d-flex align-items-start justify-content-between cus_pro_quan_blk'>
                                                                                <div>
                                                                                    <h4 className="font_14 m-0 text_black let_spa_2 text-uppercase fw-reg">{CURRENCY_FORMAT(product.custom_bundle_discount_price)}</h4>
                                                                                    <span className="font_14 mb_14 d-block text_opacity  fw_ar_reg line_through">{((product.regular_price && product.regular_price != 0 )? CURRENCY_FORMAT(product.regular_price) : '')}</span>
                                                                                </div>
                                                                                <div>
                                                                                    <span className='font_16 text_black fw-bold'>{product.quantity}</span><span className='text_opacity font_12 pl_3'>Qty</span>
                                                                                </div>
                                                                                <div>
                                                                                    <h4 className="font_14 m-0 text_black let_spa_2 text-uppercase fw_ar_bold" data-title="total">
                                                                                        {CURRENCY_FORMAT(product.custom_bundle_discount_price * product.quantity)}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                            {(this.state.subscription_detail.is_subscription == 'yes' && (product.subscription_status == '0' || product.subscription_status == null)) && (
                                                                                <span className="d-block items_text font_14 fw_ar_reg mb_9 text_red">
                                                                                    {product.cart_variation_name != null
                                                                                        ? `${product.cart_product_name} - ${product.cart_variation_name} is not available for subscription. Please remove from your cart to enable subscribe and save.`
                                                                                        : `${product.cart_product_name} is not available for subscription. Please remove from your cart to enable subscribe and save.`}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            );
                                                        }.bind(this))
                                                    }
                                                    {/* End added product list */}
                                                </div>
                                                <div className="product_upload text-center mb_22">
                                                    <img src={UploadIcon}  className="mb_15" />
                                                    <h6 className="font_12 m-0 text_opacity text-center">Add Product for your Bundle</h6>
                                                </div>
                                                {(this.state.subscription_detail.is_subscription == 'no') ? null : (
                                                    <Fragment>
                                                       <label onClick={!this.state.NotallowedtoSubscription ? this.isSubscription : null} className={`w-100 cus_bun_subscri_blk subscri_blk pro_subscri_blk d-flex align-items-center checkbox_label mx-0 mb_22 radio_label ${this.state.NotallowedtoSubscription ? 'disable disabled' : ''}`} htmlFor="purchase_type_subscription" style={this.state.NotallowedtoSubscription ? { opacity: '0.5', cursor: 'not-allowed' } : {}}>
                                                            <div className="sub_lft position-relative">
                                                                <input defaultValue="subscription" name="purchase_type" id="purchase_type_subscription" className="" type="radio" checked={this.state.subscription == "yes"} onChange={this.isSubscription} disabled={this.state.NotallowedtoSubscription}/>
                                                                <span className="checbox"></span>
                                                            </div>
                                                            <div className="sub_rgt pl_16">
                                                                <div className="d-flex align-items-center">
                                                                    <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0">
                                                                        Subscribe and Save {this.state.subscription_detail.subscription_save_percentage || 0}% for 1 month
                                                                    </h3>
                                                                </div>
                                                                {this.state.subscription != 'no' && (
                                                                    <div className="d-flex align-items-center mt_5">
                                                                        <span className="d-block font_14 text_black fw_ar_bold let_spa_2">
                                                                            Order will ship every: 1 Month(s)
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </label>
                                                    </Fragment>
                                                )}
                                                <div>
                                                    <button 
                                                        className={`shop_more add_cart_btn font_16 banner_text text-center fw_ar_reg add_to_bundle add_bundle_btn ${totalProductQtyAdded < 2 ? 'disable' : ''}`}
                                                        onClick={totalProductQtyAdded >= 2 ? this.alertModel : undefined} disabled={totalProductQtyAdded < 2}>
                                                        Add Bundle to your Cart { CURRENCY_FORMAT(totalProductQtyAdded < 2 ? BUNDLE_SUB_TOTAL() : this.state.subscription == 'yes' ? this.state.discountPrice: BUNDLE_SUB_TOTAL() 
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <SingleProductModal product={this.state.productDetails} />
                                    <SingleProductNotAvailableModal productLink={this.state.ProductLink} />
                                </div>
                            </div>
                        </Fragment>
                }
                
                 {/* Custom bundle product modal start  */}
                 <div className="modal fade" id={'alertModel'} tabIndex="-1" role="dialog" aria-labelledby="alertModel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header cus-modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body montserrat">
                                <p className="font_16 text_black fw_ar_reg m-0">
                                    You cannot add a custom bundle product to your cart while a regular product is already in it, You can delete regular product to proceed with the custom bundle product.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={(e) => this.handleCart()} type="button" className="cus_button" data-dismiss="modal">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Custom bundle product modal  */}
            </Fragment>
        );
    }
}

export default CreateCustomBundle;
